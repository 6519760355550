<div *ngIf="!isProductsActions">
  <div class="icons-tables gw-table-content gw-justify-end">
    <ng-container *ngTemplateOutlet="defaultActions"></ng-container>
  </div>
</div>

<div *ngIf="isProductsActions">
  <span class="icons-tables gw-table-content gw-justify-end">
    <!-- <a [matTooltip]="'Admin Management'">
      <i mat-raised-button *ngIf="isShowAdminButton" (click)="emitAdminButton()" class="poll-icon material-icons padding-left--xs">dashboard</i>
    </a> -->
    <!-- <a [matTooltip]="productNotes" *ngIf="isItemActive"><i mat-raised-button *ngIf="isShowNotesButton && !isMobile"
        (click)="emitItemForDetails()" class="poll-icon material-icons padding-left--xs">note</i>
    </a> -->
    <a [matTooltip]="'Product Insights'" *ngIf="isItemActive">
      <i mat-raised-button *ngIf="isShowDetailsButton" (click)="emitItemForDetails()" class="poll-icon material-icons padding-left--xs">poll</i>
    </a>

    <button [matTooltip]="'More Options'" mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu" *ngIf="!isMobile">
      <mat-icon class="menu">more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button *ngIf="shouldShowUpdateCompetitors" disableElementForDemoPlatform (click)="emitItemForUpdateCompetitors()" mat-menu-item>
        <mat-icon>refresh</mat-icon>
        <span>Update Competitors</span>
      </button>
      <ng-container *ngIf="!isShopifyIntegration">
        <button disableElementForDemoPlatform *ngIf="isProductEditable" (click)="emitItem()" mat-menu-item>
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
      </ng-container>
      <button disableElementForDemoPlatform *ngIf="isShowDownloadButton" (click)="emitItemForDownload()" mat-menu-item>
        <mat-icon>file_download</mat-icon>
        <span>{{downloadButtonName}}</span>
      </button>
      <ng-container *ngIf="!isShopifyIntegration">
        <button disableElementForDemoPlatform *ngIf="shouldShowDeleteButton" (click)="showDeleteYesNoDialog()"
          mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </ng-container>      
      <button disableElementForDemoPlatform *ngIf="shouldShowActivateButton && !item.isActive && sessionService.isAdmin" mat-menu-item
        (click)="emitItemAfterActivationStatusChange(false)">
        <mat-icon>play_arrow</mat-icon>
        <span>Activate</span>
      </button>
      <button disableElementForDemoPlatform *ngIf="shouldShowActivateButton && item.isActive && sessionService.isAdmin" mat-menu-item
        (click)="emitItemAfterActivationStatusChange(true)">
        <mat-icon>stop</mat-icon>
        <span>Deactivate</span>
      </button>
      <button disableElementForDemoPlatform (click)="copyTitle()" [cdkCopyToClipboard]="ProductTitle" mat-menu-item>
        <mat-icon>file_copy</mat-icon>
        <span>Copy Title</span>
      </button>
      <button disableElementForDemoPlatform (click)="copySku()" [cdkCopyToClipboard]="ProductSku" mat-menu-item>
        <mat-icon>file_copy</mat-icon>
        <span>Copy SKU</span>
      </button>
      <button disableElementForDemoPlatform (click)="copyBarcode()" [cdkCopyToClipboard]="ProductBarcode" mat-menu-item>
        <mat-icon>file_copy</mat-icon>
        <span>Copy Barcode</span>
      </button>
      <button disableElementForDemoPlatform (click)="copyId()" [cdkCopyToClipboard]="ProductID"
        *ngIf="sessionService.isAdmin && shouldShowCopyButtons" mat-menu-item>
        <mat-icon>file_copy</mat-icon>
        <span>Copy ID</span>
      </button>
      <button disableElementForDemoPlatform (click)="showMetafields()" *ngIf="sessionService.isAdmin" mat-menu-item>
        <mat-icon>dvr</mat-icon>
        <span>Show Metafields</span>
      </button>
      <button disableElementForDemoPlatform (click)="separateVariants()" *ngIf="shouldShowSeperateVariantsOption"
        mat-menu-item>
        <mat-icon>call_split</mat-icon>
        <span>Separate Variants</span>
      </button>
    </mat-menu>
  </span>
</div>

<ng-template #defaultActions>
  <ng-container *ngIf="isPricingStrategyActions || isTrafficDataAvailable">
    <a [matTooltip]="isPricingStrategyActions ? 'Pricing Strategy Insights' : 'Competitors Insights'" *ngIf="isItemActive">
      <i mat-raised-button *ngIf="isShowDetailsButton" (click)="emitItemForDetails()" class="poll-icon material-icons">poll</i>
    </a>
    <a *ngIf="shouldShowRollbackButton" [matTooltip]="'Rollback'"><i mat-raised-button (click)="showRollbackModal()"
      class="material-icons padding-left--xs">undo</i></a>
    <a *ngIf="shouldShowApplyButton" [matTooltip]="'Apply Now'"><i mat-raised-button (click)="showApplyModal()"
      class="material-icons padding-left--xs">play_arrow</i></a>
  </ng-container>

  <ng-container *ngIf="isPricingStrategyActions || isReportsActions">
    <a *ngIf="shouldShowCopyButton" [matTooltip]="'Copy'"><i mat-raised-button (click)="showCopyModal()"
      class="material-icons" [ngClass]="isPricingStrategyActions ? 'padding-left--xs' : ''">content_copy</i></a>
  </ng-container>

  <ng-container *ngIf="isPricingDiscountActions">
    <a *ngIf="shouldShowRollbackButton" [matTooltip]="'Rollback'"><i mat-raised-button (click)="showRollbackModal()"
      class="material-icons padding-left--xs">undo</i></a>
    <a *ngIf="shouldShowApplyButton" [matTooltip]="'Apply Now'"><i mat-raised-button (click)="showApplyModal()"
      class="material-icons padding-left--xs">play_arrow</i></a>
    <a [matTooltip]="'Bulk Update Insights'" *ngIf="isItemActive">
      <i mat-raised-button *ngIf="isShowDetailsButton" (click)="emitItemForDetails()" class="poll-icon material-icons padding-left--xs">poll</i>
    </a>
  </ng-container>

  <ng-container *ngIf="isCompetitorUrlsManagementActions">
    <a [matTooltip]="'Rerun'"><i mat-raised-button (click)="showRerunModal()"
      class="material-icons padding-left--xs">replay</i></a>

    <a [matTooltip]="'Rich Results Test'"><i mat-raised-button (click)="emitItemForDetails()"
      class="material-icons padding-left--xs">logout</i></a>
  </ng-container>

  <a [matTooltip]="'Edit'"><i mat-raised-button (click)="emitItem()" *ngIf="isProductEditable" class="material-icons" [ngClass]="{'padding-left--xs': (isPricingStrategyActions || isTrafficDataAvailable || isPricingDiscountActions || isReportsActions)}">edit</i></a>

  <a [matTooltip]="'Delete'"><i mat-raised-button *ngIf="shouldShowDeleteButton" (click)="showDeleteYesNoDialog()"
      class="material-icons padding-left--xs">delete</i></a>
  
  <a class="price-suggestion" [matTooltip]="item.suggestionsCount + ' price suggestions available'"><i mat-raised-button *ngIf="isPricingStrategyActions && item.suggestionsCount > 0" class="material-icons padding-left--xs">tips_and_updates</i></a>  

  <a [matTooltip]="'Download'"><i mat-raised-button *ngIf="isShowDownloadButton" (click)="emitItemForDownload()"
      class="material-icons padding-left--xs">file_download</i></a>
</ng-template>


<pm-ep-yes-no-dialog [title]="ConfirmDeleteProduct" (dialogConfirm)="onConfirmYesNoDialog()"
  (dialogCancel)="onCancelYesNoDialog()" [(shouldShow)]="shouldShowDeleteProductText" [showCloseDeleteModal]="isShowCloseDeleteModal">
  {{ConfirmDeleteMessage}}</pm-ep-yes-no-dialog>

  <pm-ep-yes-no-dialog [title]="ConfirmApplyTitle" (dialogConfirm)="onCofirmApplyDialog()"
  (dialogCancel)="onCancelApplyDialog()" [(shouldShow)]="shouldShowApplyModal">
  {{ConfirmApplyMessage}}</pm-ep-yes-no-dialog>

  <pm-ep-yes-no-dialog [title]="ConfirmRollbackTitle" (dialogConfirm)="onCofirmRollbackDialog()"
  (dialogCancel)="onCancelRollbackDialog()" [(shouldShow)]="shouldShowRollbackModal">
  {{ConfirmRollbackMessage}}</pm-ep-yes-no-dialog>

  <pm-ep-yes-no-dialog [title]="ConfirmRerunModalTitle" [isRerunModal]="true" (dialogConfirm)="onConfirmRerunModalDialog()"
  (dialogCancel)="onCancelRerunModalDialog()" [(shouldShow)]="shouldShowRerunCompetitorUrlSearchModal"
  [cancelBtnTitle]="'CANCEL'" [confirmBtnTitle]="'RUN'" [isBackDropStatic]="true">
  {{ConfirmRerunModalMessage}}</pm-ep-yes-no-dialog>

  <pm-ep-yes-no-dialog [title]="ConfirmCopyTitle" (dialogConfirm)="onConfirmCopyDialog()"
  (dialogCancel)="onCancelCopyDialog()" [(shouldShow)]="shouldShowCopyModal">
  {{ConfirmCopyMessage}}</pm-ep-yes-no-dialog>
