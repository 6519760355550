import { EventEmitter, Component, ViewChild, Input, Output, TemplateRef, ContentChild, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CompetitorsService } from 'app/common/services/competitors.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SessionService } from 'app/common/services/session.service';
import { ComparableProductsService } from 'app/common/services/comparableProducts.service';
import { CompetitorType } from 'app/common/interfaces/competitors/CompetitorType';
import { IProduct } from 'app/common/interfaces/IProduct';
import { IPagination } from 'app/common/interfaces/IPagination';
import { TableColumn } from 'app/common/interfaces/table-column.interface';
import { ISearchableProduct } from 'app/common/interfaces/ISearchableProduct';
import { IComparableProductData } from 'app/common/interfaces/IComparableProductData';
import { ExportType } from 'app/common/enums/ExportType.enum';
import { ReportsService } from 'app/common/services/reports.service';
import { ProductsService } from 'app/common/services/product.service';
import { EpLoadingService } from 'app/common/services/ep-loading.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Platform } from '@angular/cdk/platform';
import { GwLocalStorageService, StorageDict } from 'app/common/services/gw-local-storage.service';
import { MatSelectChange } from '@angular/material/select';
import { ISortData } from 'app/common/interfaces/ISortingData';

@Component({
  selector: 'pm-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductsTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() id: string;
  @Input() showFilter: boolean = true;
  @Input() showExportOptions: boolean = false;
  @Input() showColumnFilterOptions: boolean = false;
  @Input() showPercentagePriceConverter: boolean = false;
  @Input() orderByPrice : boolean = false;
  @Input() showCreateReport: boolean = false;

  @Input() products: IProduct[];
  
  _pageSize : number = 5;
  _pageIndex : number = 0;

  adminPageIndex : string;

  @Input() pagination : IPagination;
  @ContentChild('actionTemplate') actionTemplate: TemplateRef<any>;
  @ContentChild('competitorsTemplate') competitorsTemplate: TemplateRef<any>;

  shouldShowExportOptions: boolean = false;

  pageSizeOptions : Array<number> = [5, 10, 20, 50];

  @Input()
  columns: TableColumn[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: !this.isMobile },
    { label: 'Image', property: 'imageUrlForInsights', type: 'image', visible: !this.isMobile },
    { label: 'Title', property: 'title', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Price', property: 'price', type: 'text', visible: true },
    { label: 'Unit Price', property: 'unitPrice', type: 'text', visible: false },
    { label: 'Cost', property: 'cost', type: 'text', visible: false },
    { label: 'CompareAt Price', property: 'compareAtPrice', type: 'text', visible: false },
    { label: 'Package Quantity', property: 'packageQuantity', type: 'text', visible: false },
    { label: 'Date', property: 'date', type: 'text', visible: false },
    { label: 'Competitor', property: 'vendor', type: 'text', visible: true },
    { label: 'Search Rank', property: 'searchRank', type: 'text', visible: false },
    { label: 'Google Shopping Rank', property: 'pageRank', type: 'text', visible: false },
    { label: 'Paid Rank', property: 'paidRank', type: 'text', visible: false },

    { label: 'Source', property: 'source', type: 'text', visible: false },
    { label: 'Shipping Price', property: 'shippingPrice', type: 'text', visible: false },
    { label: 'Tax', property: 'tax', type: 'text', visible: false },
    { label: 'Match', property: 'match', type: 'text', visible: false },
    { label: 'Delete', property: 'delete', type: 'text', visible: !this.isMobile },
    { label: 'In Stock', property: 'isAvailable', type: 'text', visible: true },    
    { label: 'Verified', property: 'verified', type: 'text', visible: false, removed : true},
    { label: 'External Id', property: 'externalId', type: 'text', visible: false },
    { label: 'Unit', property: 'unit', type: 'text', visible: false },
    { label: 'Volume', property: 'volume', type: 'text', visible: false },
  ];

  @Input() isMainProductTable: boolean = true;

  shouldShowDeleteBulkProductsDialog = false;
  variantExpandedElement: ISearchableProduct | null;

  private currentSort: Sort = { active: '', direction: '' };

  get columnsForSelect(){
    return this.columns.filter(x => x.removed !== true);
  }

  get displayedColumns(): string[] {
    return this.columnSelect;
  }

  public get exportType(): typeof ExportType {
    return ExportType;
  }

  @Output() onRowClickEmitter: EventEmitter<object> = new EventEmitter<object>();
  @Output() onDeleteClick: EventEmitter<IProduct[]> = new EventEmitter<IProduct[]>();
  @Output() emitItemForView: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitItemForUrlsManagement: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitExportProductTable : EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangedEmit: EventEmitter<object> = new EventEmitter<Object>();
  @Output() emitCreateReport : EventEmitter<any> = new EventEmitter<any>();
  @Output() emitSorting: EventEmitter<ISortData> = new EventEmitter<ISortData>();

  @Output()
  _dataSource = new MatTableDataSource<IProduct>([]);

  errorMessage: any;
  imageWidth = 80;
  imageHeight = 100;
  imageHeight2 = 40;
  imageMargin = 2;
  selection = new SelectionModel<IProduct>(true, []);
  // paginatorPageIndex = 0;

  constructor(
    private competitorService: CompetitorsService,
    private sessionService: SessionService,
    private comparableProductsService: ComparableProductsService,
    private reportService: ReportsService,
    private productService: ProductsService,
    private epLoadingService: EpLoadingService,
    private deviceService: DeviceDetectorService,
    private _platform: Platform,
    private localStorageService: GwLocalStorageService) {
  }

  // pageSizeOptions = [5, 10, 20, 50, 100, 200];


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {      
    if(this.sort)
      this._dataSource.sort = this.sort;

    this._dataSource.paginator = this.paginator;

    setTimeout(() => {
      this.setColumnSelect();
    }, 1000);
    
    this.loadData();

    this.showAdminColums();

    this.sessionService.userDataLoaderEventEmitter.subscribe(() => {
      this.showAdminColums();
    });

    this._dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'verificationStatus.status': return item.verificationStatus?.status ?? 0;
        default: return item[property];
      }
    };    

    if(this.sort)
      this.applySorting(this.sort);
  }

  private applySorting(sort : MatSort) {
    sort.sort(({ id: 'averageDifference', start: 'desc' }) as MatSortable);
    sort.sort(({ id: 'verificationStatus.status', start: 'desc' }) as MatSortable);
    sort.sort(({ id: 'isActive', start: 'desc' }) as MatSortable);

    if (this.orderByPrice)
      sort.sort(({ id: 'price', start: 'asc' }) as MatSortable);
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  private showAdminColums(columns = this.columns) {
    if(columns == null || columns?.length == 0){
      return [];
    }

    let verifiedColumn = columns.find((x :any) => x.property == "verified" || x == "verified");
    let isMatchedByAlgoColumn = columns.find((x :any) => x.property == "isMatchedByAlgo");
  
    if (this.sessionService.isAdmin) {
      if (typeof verifiedColumn == "object" && verifiedColumn){
        verifiedColumn.visible = true;
        verifiedColumn.removed = false;
      }

      return columns;
    }
    else {
      if (typeof verifiedColumn == "object" && verifiedColumn){
        verifiedColumn.visible = false;                
      }
      return columns.filter((x :any) => x.property != "verified" && x != "verified");
    }
  }
  
  get columnSelect(): Array<string> {
    let columns = this.isMainProductTable ? JSON.parse(this.localStorageService.get(StorageDict.productColumns)) : JSON.parse(this.localStorageService.get(StorageDict.productInsightsColumns));
    
    columns = this.showAdminColums(columns);
    this.sessionService.hideCostColumnIfNecessary(columns);

    return columns;
  }
  
  // @ViewChild(MatPaginator) set MatPaginator(paginator: MatPaginator) {
  //   this._dataSource.paginator = paginator;
  // }

  @ViewChild(MatSort) set MatSort(sort: MatSort) {
    this._dataSource.sort = sort;
  }

  set dataSource(products: IProduct[]) {
    if(this.pagination){
      this._dataSource.data = products;
    }
    else{
      this._dataSource = new MatTableDataSource<IProduct>(products);

      this._dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'verificationStatus.status': return item.verificationStatus?.status ?? 0;
          default: return item[property];
        }
      };
  
      if(this.sort){
        this.sort.sort(({ id: 'averageDifference', start: 'desc' }) as MatSortable);
        this.sort.sort(({ id: 'verificationStatus.status', start: 'desc' }) as MatSortable);
        this.sort.sort(({ id: 'isActive', start: 'desc' }) as MatSortable);
      }    
  
      this._dataSource.sort = this.sort;
      this._dataSource.paginator = this.paginator;
    }    
    this.shouldShowExportOptions = this.shouldShowExportOptionsOnMainData();
  }

  hasProducts(): boolean {
    return this.products?.length > 0;
  }

  get totalRows() :number {    
    if(this.pagination){
      return this.pagination.totalResults;
    }
    else if(this.products?.length > 0){
      return this.products.length;
    }

    return 0;
  }

  get pageSize() {
    if(this.pagination){
      return this.pagination.pageSize;
    }
    return this._pageSize;
  }

  get pageIndex() {
    if(this.pagination){
      return this.pagination.pageIndex;
    }
    return this._pageIndex;
  }

  get comparableData(): IComparableProductData {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      searchableProductId: this.id,
      from: today.toISOString()
    };
  }

  get isProducts(): boolean {
    return this.products?.length > 0;
  }
  
  get productsMessage(): string {
    if(this.sessionService.isFinishedOnBoarding)
      return "No Products Found";

    if (this.sessionService.currentTenant?.settings?.onBoardingOptions?.isDone && this.sessionService.currentTenant?.settings?.onBoardingOptions?.isProvisioning)
      return "We are syncing your products, it might take a few minutes.";
    else
      return "No Products Found";
  }

  getFormattedTax(tax: number): string {
    if (isNaN(tax)) {
      return this.currencySymbol + '0';
    }
    return this.currencySymbol + tax.toFixed(1);
  }  
  onRowClick(row: IProduct): void {
    this.onRowClickEmitter.emit(row);
  }

  handleVariantExpansion(element: any) {
    this.variantExpandedElement = this.variantExpandedElement === element ? null : element;
  }

  shouldShowVariantExpansion(element: any) {
    return element != null && this.variantExpandedElement != null && element == this.variantExpandedElement;
  }

  loadData(): void {
    if(this.products?.length > 0){
      let products = this.products;
      if(this.orderByPrice){
        products = products.sort((a, b) => a.price - b.price);
      }
      this.dataSource = products;
    
      if(this.pagination?.totalResults > 0 && this._dataSource.paginator){
        this._dataSource.paginator.length = this.pagination.totalResults;
      }
    }
  }

  getLogoUrl(vendorName: string): string {
    let imageUrl = 'none';

    if (vendorName) {
      let competitor = this.competitorService.competitorsMap.get(vendorName.toLowerCase());
      if (competitor && competitor.imageUrl && competitor.type == CompetitorType.SaleChannel) {
        imageUrl = `url(${competitor.imageUrl})`;
      }
    }
    // return returnValue;
    return imageUrl;
  }

  emitItemForDetails(product: Object): void {
    this.emitItemForView.emit(product);
  }

  emitItemForManageUrls(product: Object): void {
    this.emitItemForUrlsManagement.emit(product);
  }

  getUrl(url: string): string {
    return `url(${url})`;
  }

  filterTable = (value: string) => {
    this._dataSource.filter = value.trim().toLocaleLowerCase();
    this.shouldShowExportOptions = this.shouldShowExportOptionsOnFilteredData();
  }

  normalizePercent(percent: number): string {
    if (percent > 100) {
      percent = 100;
    }
    return percent.toFixed(0);
  }

  onDeleteRow(entity: IProduct) {
    let data = [entity];
    if (this.numberOfSelectedProducts > 0) {
      data = this.selection.selected.map(x => x);
    }

    this.onDeleteClick.emit(data);
  }

  get isDemoPlatform(): boolean {
    return this.sessionService.isDemoPlatform
  }

  // changePaginatorPage(){
  //   this.paginator.page.next({      
  //     pageIndex: this.paginatorPageIndex,
  //     pageSize: this.paginator.pageSize,
  //     length: this.paginator.length
  //   });
  // }

  get isAdmin() : boolean {
    return this.sessionService.isAdmin;
  }

  isSyncing(product: ISearchableProduct): boolean {
    return product.lastSearch == null && product.isActive;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.numberOfSelectedProducts;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  get numberOfSelectedProducts() : number {
    return this.selection.selected.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this._dataSource.data.forEach(row => this.selection.select(row));
  }

  async onVerifiedSliderChange(product: IProduct, event: MatSlideToggleChange): Promise<void> {
    let currentStatus = !event.checked;
    let targetStatus = event.checked;

    this.comparableProductsService.changeVerificationStatus(product.searchableProductId, targetStatus, product).subscribe(
      () => {
        currentStatus = targetStatus;
      },
      err => {
        //don't change the status
        console.log(`failed to change comprable product verification status for: ${product.externalId}`);
      },
      () => {
        event.source.checked = currentStatus;
      }
    );
  }

  shouldShowExportOptionsOnMainData(): boolean {
    if (this._dataSource) {
      return this._dataSource?.data?.length > 0;
    } else {
      return false;
    }
  }

  shouldShowExportOptionsOnFilteredData(): boolean {
    if (this._dataSource) {
      return this._dataSource?.filteredData?.length > 0;
    } else {
      return false;
    }
  }

  setColumnSelect() {
    if(!this.columnSelect || this.columnSelect.length <= 0) {
      if (this.isMainProductTable)
        this.localStorageService.set(StorageDict.productColumns, JSON.stringify(this.columns.filter(column => column.visible).map(column => column.property)));
      else
        this.localStorageService.set(StorageDict.productInsightsColumns, JSON.stringify(this.columns.filter(column => column.visible).map(column => column.property)));
    }
  }
  exportProducts() {    
    const specificIds = this._dataSource?.data?.map(product => product.id)?.slice();

    this.emitExportProductTable.emit({
      columns : this.columnSelect,
      specificIds
    });    
  }

  createReport() {
    const specificIds = this._dataSource?.data?.map(product => product.id)?.slice();

    this.emitCreateReport.emit({
      columns : this.columnSelect,
      specificIds
    });
  }

  get deleteBulkProductsDialogTitle() : string {
    return "Delete " + this.numberOfSelectedProducts.toString() + (this.numberOfSelectedProducts == 1 ? " product" : " products");
  }

  get deleteBulkProductsDialogMessage() : string {
    return "Are you sure you want to delete selected " + this.numberOfSelectedProducts.toString() + (this.numberOfSelectedProducts == 1 ? " product" : " products") +"? all the data will be lost";
  }

  deleteBulkProducts(): void {
    if (!this.sessionService.isDemoPlatform) {
      this.shouldShowDeleteBulkProductsDialog = true;
    }
  }

  onConfirmDeleteBulkProductsDialog(): void {
    let productIds = [];
    if (this.numberOfSelectedProducts > 0) {
      productIds = this.selection.selected.map(x => x.id);
    }
    this.productService.deleteBulkProducts(productIds);
    this.shouldShowDeleteBulkProductsDialog = false;
    this.selection.clear();
  }

  onCancelDeleteBulkProductsDialog(): void {
    this.shouldShowDeleteBulkProductsDialog = false;
  }

  async updateCompetitors(product: ISearchableProduct): Promise<void> {
    this.epLoadingService.start();
    await this.productService.triggerSearchableProductUpdate(product.id).toPromise();
  }

  async updateCompetitorsBulk(): Promise<void> {
    this.epLoadingService.start();
    let productIds = [];
    if (this.numberOfSelectedProducts > 0) {
      productIds = this.selection.selected.map(x => x.id);
    }
    await this.productService.triggerSearchableProductUpdateBulkAsync(productIds).toPromise();
  }

  pageChanged(event: PageEvent) {
    this._pageIndex = event.pageIndex;
    this._pageSize = event.pageSize;   
    this.pageChangedEmit.emit(event);
  }

  setPageChanged(targetValue : any) {
    let pageIndex = parseInt(targetValue);
    this.pageChanged({
      pageIndex : pageIndex,
      pageSize : this.pageSize,
      length : this.totalRows
    });        
  }

  get isProductsPriceView(): boolean {
    return this.productService.isProductsPriceView;    
  }

  get percentagePriceConverterValue(): string {
    return this.productService.isProductsPriceView ? "price" : "percentage";    
  }

  get currencySymbol(): string {
    return this.sessionService.currencySymbol;
  }
  
  onChangePercentagePriceConverter(event: MatButtonToggleChange): void {
    if (event && event.value == "price")
      this.localStorageService.set(StorageDict.percentagePriceConverterValue, "price");      
    else
      this.localStorageService.set(StorageDict.percentagePriceConverterValue, "percentage");      
  }

  onChangeFilterColumns(event: MatSelectChange): void {
    if (event && event.value) {
      if (this.isMainProductTable)
        this.localStorageService.set(StorageDict.productColumns, JSON.stringify(event.value));
      else
        this.localStorageService.set(StorageDict.productInsightsColumns, JSON.stringify(event.value));
    }
  }

  sortData(event: Sort): void {
    // Check if direction is empty, and set a default value
    if (!event.direction)
      event.direction = 'asc'; // or 'desc' based on your preference

    // Save the current sort state
    this.currentSort = event;

    // Apply the sort to the data source
    this.applySort();
  }

  private applySort(): void {
    // Apply the sort to the MatTableDataSource
    this._dataSource.sort = this.sort;
    this._dataSource.sort.active = this.currentSort.active;
    this._dataSource.sort.direction = this.currentSort.direction;

    let sortData: ISortData = { sortingColumn: this.currentSort.active == 'margin' ? 'marketdiff' : this.currentSort.active, sortingDirection: this.currentSort.direction };
    this.emitSorting.emit(sortData);
  }
}