import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IPricingCondition } from '../interfaces/conditions/pricing/IPricing-Condition';
import { EpApiService } from './api/ep-api.service';
import { EpLoadingService } from './ep-loading.service';
import { IRule } from '../interfaces/IRule';
import { IGetPriceUpdatesByRuleRequest } from '../interfaces/requests/IGetPriceUpdatesByRuleRequest';
import { IPriceUpdate } from '../interfaces/IPriceUpdate';
import { IGetRulesResult } from '../interfaces/rules/IGetRulesResult';
import { ISearchableProduct } from '../interfaces/ISearchableProduct';
import * as moment from 'moment';
import { PriceUpdateSourceType } from '../enums/PriceUpdateSourceType.enum';
import { IProfitSummaryResult } from '../interfaces/orders/IProfitSummaryResult';
import { ISalesSummaryResult } from '../interfaces/orders/ISalesSummaryResult';
import { IGetOrdersByRuleRequest } from '../interfaces/requests/IGetOrdersByRuleRequest';
import { IOrder } from '../interfaces/IOrder';
import { IPriceSuggestion } from '../interfaces/IPriceSuggestion';
import { IApplyPriceSuggestionRequest } from '../interfaces/requests/IApplyPriceSuggestionRequest';
import { IDeletePriceSuggestionRequest } from '../interfaces/requests/IDeletePriceSuggestionRequest';

const IWouldLikeToSetThePricesOfMy = "I would like to set the prices of my ";
const IWouldLikeToSetThePricesOf = "I would like to set the price of ";
const ToBe = " to be";
const InterChangeSentencePlaceholder = " {{InterSentence}} ";
const dateFormat = "DD/MM/YYYY";

@Injectable({
  providedIn: 'root'
})
export class RulesService {


  rulesRelatedProducts: ISearchableProduct[];

  ruleListChangedEmitter = new EventEmitter<Object>();

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService) { }

  getRules(): Observable<IGetRulesResult> {
    return this.epApiService.get<IGetRulesResult>("Rules/Extended").pipe(
      tap(data => {
        this.rulesRelatedProducts = data.relatedProducts;
        this.ruleListChangedEmitter.emit();
        return;
      }),
      catchError(this.handleError)
    );
  }

  create(rule: IRule): Observable<IRule> {
    this.epLoadingService.start();
    return this.epApiService.post<IRule>('Rules', rule).pipe(
      tap(data => { this.epLoadingService.stop("Rules Created Successfully"); return; }),
      catchError(this.handleError)
    );
  }


  enableDynamicPricing(): Observable<IRule> {
    this.epLoadingService.start();
    return this.epApiService.post<IRule>('Tenants/EnableDynamicPricing', {}).pipe(
      tap(data => { this.epLoadingService.stop("Dynamic Pricing Enabled Successfully"); return; }),
      catchError(this.handleError)
    );
  }

  apply(id: string) {
    this.epLoadingService.start();
    return this.epApiService.post<IRule>('Rules/ExecuteRule', {
      ruleId: id
    }).pipe(
      tap(data => { this.epLoadingService.stop("Rule Executed Successfully"); return; }),
      catchError(this.handleError)
    );
  }

  rollback(id: string) {
    this.epLoadingService.start();
    return this.epApiService.post<IRule>('Rules/RollbackRule', {
      ruleId: id
    }).pipe(
      tap(data => { this.epLoadingService.stop("Rule Rollbacked Successfully"); return; }),
      catchError(this.handleError)
    );
  }

  rollbackPricingCondition(id: string) {
    this.epLoadingService.start();
    return this.epApiService.post<IRule>('Rules/RollbackPricingConditionRule', {
      ruleId: id
    }).pipe(
      tap(data => { this.epLoadingService.stop("Rule Rollbacked Successfully"); return; }),
      catchError(this.handleError)
    );
  }

  update(report: IRule): Observable<IRule> {
    this.epLoadingService.start();
    return this.epApiService.patch<IRule>(`Rules/${report.id}`, report).pipe(tap(() => {
      this.epLoadingService.stop("Rules Updated Successfully");
    }));
  }

  delete(idToDelete: string): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.delete<IRule>(`Rules/${idToDelete}`).pipe(tap(() => {
      this.epLoadingService.stop("Rules Deleted Successfully");
    }));
  }

  getRuleDescription(condition: IPricingCondition, isSingleProductPricingStrategy: boolean): string {
    return (isSingleProductPricingStrategy ? IWouldLikeToSetThePricesOf : IWouldLikeToSetThePricesOfMy)
      + condition.description.replace(InterChangeSentencePlaceholder, ToBe)
  }

  getPriceUpdates(data: IGetPriceUpdatesByRuleRequest): Observable<IPriceUpdate[]> {
    return this.epApiService.post<IPriceUpdate[]>('PricingUpdates/GetPriceUpdatesByRule', data).pipe(
      tap(data => { return; }),
      catchError(this.handleError)
    );
  }

  getPriceSuggestions(data: IGetPriceUpdatesByRuleRequest): Observable<IPriceSuggestion[]> {
    return this.epApiService.post<IPriceSuggestion[]>('PricingSuggestions/GetPriceSuggestionsByRule', data).pipe(
      tap(data => { return; }),
      catchError(this.handleError)
    );
  }

  applyPriceSuggestion(data: IApplyPriceSuggestionRequest): Observable<IPriceSuggestion[]> {
    this.epLoadingService.start();
    return this.epApiService.post<IPriceSuggestion[]>('PricingSuggestions/ApplyPriceSuggestionPrice', data).pipe(
      tap(data => { this.epLoadingService.stop("Price Suggestion applied successfully"); return; }),
      //todo: handle error also add toast for success and error
      catchError(this.handleError)
    );
  }

  deletePriceSuggestion(data: IDeletePriceSuggestionRequest): Observable<IPriceSuggestion> {
    this.epLoadingService.start();
    return this.epApiService.post<IPriceSuggestion>('PricingSuggestions/Delete', data).pipe(
      tap(data => { this.epLoadingService.stop("Price Suggestion deleted successfully"); return; }),
      //todo: handle error also add toast for success and error
      catchError(this.handleError)
    );
  }

  getPriceUpdatesByQuery(data: IGetPriceUpdatesByRuleRequest): Observable<IPriceUpdate[]> {
    return this.epApiService.post<IPriceUpdate[]>('PricingUpdates/GetPriceUpdatesByQuery', data).pipe(
      tap(data => { return; }),
      catchError(this.handleError)
    );
  }

  getOrders(data: IGetOrdersByRuleRequest): Observable<IOrder[]> {
    return this.epApiService.post<IOrder[]>('Orders/GetOrdersByRule', data).pipe(
      tap(data => { return; }),
      catchError(this.handleError)
    );
  }

  preparePriceUpdate(priceUpdate: IPriceUpdate) {
    try {
      priceUpdate.margin = this.calculateMargin(
        priceUpdate.previousPrice,
        priceUpdate.targetPrice
      );

      priceUpdate.updateTime = this.getFormattedDate(
        priceUpdate.updateDate
      );

      priceUpdate.sourceTypeName = PriceUpdateSourceType[priceUpdate.sourceType]
    }
    catch (e) {
    }
  }

  calculateMargin(
    previousPrice?: number,
    targetPrice?: number
  ): string {
    if (previousPrice && targetPrice) {
      return (
        (((previousPrice - targetPrice) / previousPrice) * 100 * -1)?.toFixed(
          2
        ) + "%"
      );
    }
    return "";
  }


  getFormattedDate(stringDate: string): string {
    let date = null;
    if (stringDate == null)
      //some default date
      date = moment(1432252800).toDate();

    date = moment(stringDate);

    return date.format(dateFormat);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }


  transformIntoDate(stringDate: string): Date {
    if (stringDate == null)
      //some default date
      return moment(1432252800).toDate();

    return moment(stringDate, dateFormat).toDate();
  }

  isShowDiffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): boolean {
    return summary?.diffPercentage != 0;
  }

  diffClass(summary: IProfitSummaryResult | ISalesSummaryResult): string {
    return summary?.isPositiveDiff ? 'positive-diff' : 'negative-diff';
  }

  isUpDiffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): boolean {
    return summary?.isPositiveDiff;
  }

  diffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): string {
    return summary?.diffPercentage?.toFixed(2);
  }

  isShowCountDiffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): boolean {
    return summary?.countDiffPercentage != 0;
  }

  countDiffClass(summary: IProfitSummaryResult | ISalesSummaryResult): string {
    return summary?.isPositiveCountDiff ? 'positive-diff' : 'negative-diff';
  }

  isUpCountDiffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): boolean {
    return summary?.isPositiveCountDiff;
  }

  countDiffPercentage(summary: IProfitSummaryResult | ISalesSummaryResult): string {
    return summary?.countDiffPercentage?.toFixed(2);
  }
}
