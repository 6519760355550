import { Inject, Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { EpApiService } from './api/ep-api.service';
import { EpLoadingService } from './ep-loading.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { EncryptedLocalStorageService, GwLocalStorageService, StorageDict } from './gw-local-storage.service';
import { SessionService } from './session.service';
import { ITenant } from '../interfaces/tenant/ITenant';
import { IRegisterRequest } from 'app/common/interfaces/requests/IRegisterRequest';
import { IUserDataResult } from 'app/common/interfaces/responses/IUserDataResult';
import { IAuthTokenResponse } from 'app/common/interfaces/responses/IAuthTokenResponse';
import { ITenantSettings } from '../interfaces/tenant/ITenantSettings';
import { SharedService } from './shared.service';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  auth0Client: any;

  constructor(private epApiService: EpApiService,
    private epLoadingService: EpLoadingService,
    private sessionService: SessionService,
    private authService: AuthService,
    private localStorageService: GwLocalStorageService,
    private encryptedLocalStorage : EncryptedLocalStorageService,
    private sharedService: SharedService,
    @Inject(DOCUMENT) private doc: Document) {
  }

  public async register(tenantData: ITenant): Promise<void> {
    const registerRequest: IRegisterRequest = {
      tenantData
    }

    let registrationResponse = await this.epApiService.post<IUserDataResult>('Auth/Register', registerRequest).toPromise();
    this.sessionService.currentTenant = registrationResponse.tenant;
    this.sessionService.currentUser = registrationResponse.user;

    return;
  }

  public async getCurrentToken() {
    let currentToken = null;
    let accessToken = this.encryptedLocalStorage.getAccessToken();
    if (this.sharedService.isValidAccessToken(accessToken)) {
      currentToken = "Bearer " + accessToken;
    }
    else {
      const token = await this.authService.getAccessTokenSilently().toPromise();
      if (token != null) {
        currentToken = "Bearer " + token;
      }
    }

    return currentToken;
  }  

  public async getToken(email: string, ssoToken: string): Promise<IAuthTokenResponse> {
    const requestBody = {
      email,
      ssoToken
    };

    let result = await this.epApiService.post<IAuthTokenResponse>('Auth/GenerateToken', requestBody).toPromise();

    return result
  }

  public updateTenantSettings(tenantSettings: ITenantSettings): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post("Tenants/UpdateTenantSettings", tenantSettings).pipe(tap(() => {
      this.epLoadingService.stop("updated Successfully");
    }));
  }



  public updateTenantSearchTime(cron: string): Observable<void> {
    return this.epApiService.post('Tenants/UpdateTenantsProductUpdateJob', {
      cron
    }).pipe(
      tap(data => { return; }),
      catchError(this.handleError)
    );
  }


  public isUserAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  public logout() {
    this.encryptedLocalStorage.removeAccessToken();
    this.authService.logout({ returnTo: this.doc.location.origin });
  }
  public login(email: string, password: string) {

    this.auth0Client = new auth0.WebAuth({
      clientID: environment.auth.clientId,
      domain: environment.auth.domain,
      responseType: 'token',
      audience: environment.auth.audience,
      scope: "openid email profile name",
      redirectUri: window.location.origin + "/pages/platform/integrations",
      sso: true
    });

    try {
      //this.auth0Client.crossOriginVerification();
    }
    catch (ex) {
      console.log(ex);
    }

    try {

      this.auth0Client.login({
        username: email,
        password: password,
        realm: environment.auth.connection
      }, function (err, result) {
        console.log(err);
        console.log(result);
      });
    }
    catch (ex) {
      console.log(ex);
    }

  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error has occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status},error message is : ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
