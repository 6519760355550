import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { syncEntitiesArray, SyncEntityType } from 'app/common/enums/SyncEntityType.enum';
import { ICollection } from 'app/common/interfaces/ICollection';
import { IOption } from 'app/common/interfaces/IOption';
import { IntegrationsService } from 'app/common/services/integrations.service';
import { SessionService } from 'app/common/services/session.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pm-sync-form',
  templateUrl: './sync-form.component.html',
  styleUrls: ['./sync-form.component.scss']
})
export class SyncFormComponent implements OnInit, OnChanges {

  @Output() formValidityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Input()
  syncFormGroup: FormGroup;  

  syncEntities: any[];
  allCollectionsSubscription: any;
  isChanged: boolean;
  syncEntitiesSelect: FormControl;

  constructor(private integrationsService : IntegrationsService, private sessionService : SessionService) {
  }

  @Input()
  hideSyncButton : boolean;

  @Input()  
  private _tenantId: string;
    
  @Input() set tenantId(value: string) {  
    if(this._tenantId != value){
      this._tenantId = value;
      this.reloadCollections();  
    }     
  }
  
  get tenantId(): string {  
      return this._tenantId;  
  }  

  @Output()
  syncCollectionEmit: EventEmitter<object> = new EventEmitter<Object>();


  isSubmitLoading = false;  
  selectedSyncEntity: any;
  selectedCollection: ICollection;
  collections: ICollection[];
  filteredCollections :  ReplaySubject<ICollection[]> = new ReplaySubject<ICollection[]>(1);
    

  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.initializeForms();
    this.initializeFormsData();
    this.collectionsSelectFilter.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCollections();
    });  
    this.checkAndEmitValidity();    
  }

  async reloadCollections(){
    const collections = await this.integrationsService.getCollections(this.tenantId).toPromise(); 
    this.loadCollections(collections);  
  }

  
  ngOnChanges(changes: SimpleChanges) {
    // Check if selectedSyncEntity has changed
    if (changes.selectedSyncEntity && !changes.selectedSyncEntity.firstChange) {
      this.checkAndEmitValidity();
    }
  }

  // Function to handle selectedSyncEntity change
  onSelectedSyncEntityChange() {
    this.checkAndEmitValidity();
  }

  checkAndEmitValidity() {
    const isFormValid = this.syncFormGroup.valid;

    if (this.shouldShowCollectionList) {
      const isCollectionValid = !!this.selectedCollection;
      const combinedValidity = isFormValid && isCollectionValid;
      this.formValidityChanged.emit(combinedValidity);
    } else {
      // If shouldShowCollectionList is false, emit form validity directly
      this.formValidityChanged.emit(isFormValid);
    }
  }

  protected filterCollections() {
    if (!this.collections) {
      return;
    }
    // get the search keyword
    let search = this.collectionsSelectFilter.value;
    if (!search) {
      this.filteredCollections.next(this.collections.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the competitors
    this.filteredCollections.next(
      this.collections.filter(collections => collections.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private loadCollections(collections: ICollection[]) {
    this.collections = collections;
    // load the initial tenants list
    this.filteredCollections.next(this.collections?.slice());
  }
  
  get collectionsSelectFilter() {
    return this.syncFormGroup.get('collectionsSelectFilterCtrl');
  }
  
  onSelectedCollectionChange(event: MatSelectChange) {
    this.isChanged = true;
    this.selectedCollection = event.value as ICollection;    
    this.checkAndEmitValidity();
  }

  submit() {
    this.sendOnBoardingData();
  }

  private sendOnBoardingData() {
    this.isSubmitLoading = true;
    // this.sessionService.finishOnBoarding(onBoardingData).subscribe(async () => {
    //   this.sessionService.loadUserData();
    //   this.sessionService.isFinishedOnBoarding = true;            
    //   this.changeDetectChanges.detectChanges();

    //   //load calendly after view change
    // }, complete => {
    //   this.isSubmitLoading = false;
    // });
  }

  // get selectedTargetSyncEntityName(): string {
  //   return this.syncFormGroup.controls?.targetSyncEntityName?.value ?? null;
  // }

  private initializeForms(): void {      
    this.syncFormGroup = new FormGroup({
      syncEntitiesSelect: new FormControl(['', Validators.required]),
      targetSyncEntityName: new FormControl(),
      collectionsSelectFilterCtrl : new FormControl(),
      addToActiveProducts: new FormControl(false)
    });

  }  
      //targetSyncEntityName: new FormControl(),    

  isCollectionEmpty(): boolean {
    return !this.selectedCollection;
  }

  get isAdmin() : boolean {
    return this.sessionService.isAdmin;
  }
  get shouldShowEntityNameInput() {
    return this.selectedSyncEntity != null && this.selectedSyncEntity.value != SyncEntityType.First100 && this.selectedSyncEntity.value != SyncEntityType.BestSellers;
  }

  get shouldShowCollectionList() : boolean {    
    return this.selectedSyncEntity != null && this.selectedSyncEntity.value == SyncEntityType.Collection;
  }

  get syncEntityLabel() {
    if (!this.selectedSyncEntity)
      return 'Select Entity Type';

    return this.selectedSyncEntity?.title + ' name';
  }

  private initializeFormsData() {
    this.syncEntities = [...syncEntitiesArray]
    this.selectedSyncEntity = this.syncEntities.find(x => x.value == SyncEntityType.BestSellers);
  }

  get syncCollectionInfo() : any {
    return {
      syncEntityType : this.selectedSyncEntity?.value,
      targetSyncEntityName: this.selectedCollection?.name
    };
  }

  syncCollectionSubmit(){
    const addToActive = this.syncFormGroup.value.addToActiveProducts ?? false;

    this.syncCollectionEmit.emit({
      selectedSyncEntity : this.selectedSyncEntity,
      selectedTargetSyncEntityName: this.selectedCollection?.name,
      addToActiveProducts : addToActive
    });    
  }
  
  clearSearch() {
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-input").val("");
    $("#entity-search-input").children(".mat-select-search-inner").children(".mat-select-search-clear").hide();
  }
}

