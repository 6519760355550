import { EventEmitter, Injectable } from '@angular/core';
import { EpApiService } from '../../common/services/api/ep-api.service';
import { Observable } from 'rxjs';
import { EpLoadingService } from './ep-loading.service';
import { tap } from 'rxjs/operators';
import { IOnboardingData } from '../interfaces/IOnboarding-data';
import { GwLocalStorageService, StorageDict } from './gw-local-storage.service';
import { DemoPlatformId } from '../constants';
import { ActivatedRoute } from '@angular/router';
import { PricingMarginType } from '../enums/pricing-margin-type.enum';
import { RoundFunctionTypes } from '../enums/RoundFunctionTypes';
import { PriceSuffixType } from '../enums/PriceSuffixType';
import { ITenant } from '../interfaces/tenant/ITenant';
import { IUserDataResult } from 'app/common/interfaces/responses/IUserDataResult';
import { ICurrency } from '../interfaces/ICurrency';
import { IUser } from '../interfaces/IUser';
import { IIntegration } from '../interfaces/IIntegration';
import { IGetUserDataResult } from '../interfaces/IGetUserDataResult';
import { MarginActionType } from '../enums/action-margin-type.enum';
import { CompareAtPriceBaseType } from '../enums/rules/CompareAtPriceBaseType';
import { MaxLimitMarginBoundaryType } from '../enums/max-limit-margin-boundary-type.enum';

const percentageSymbol = '%';
const up = "Up";
const down = "Down";
const ninetyFive = "X.95";
const ninetyNine = "X.99";
const zero = "X.00";
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private epApiService: EpApiService,
    private epLoadingService: EpLoadingService,
    private localStorage: GwLocalStorageService,
    private activatedRoute: ActivatedRoute) {
    this.handleAdminLogin();
  }

  enableMaintenanceMode : boolean = false;
  isFinishedOnBoarding = false;
  currentUser: IUser;
  currentTenant: ITenant;
  currentCurrencySymbol: string;
  currentIntegration: IIntegration;
  
  get currencySymbol() {
    return this.currentCurrencySymbol;
  }

  get isAdmin(): boolean {
    return (this.currentUser?.email?.indexOf("ecoprice.io") > -1 || this.currentUser?.email?.indexOf("intelis.ai") > -1) && !this.isDemoPlatform;
  }

  userDataLoaderEventEmitter = new EventEmitter<Object>();

  async loadUserData(): Promise<IGetUserDataResult> {
    let result: IGetUserDataResult = { success: false, redirectToRegisterPage: true };
    try {
      let userDataResult = await this.epApiService.get<IUserDataResult>('Auth/GetUserData').toPromise();
      this.currentUser = userDataResult.user;
      this.currentTenant = userDataResult.tenant;
      this.currentIntegration = userDataResult.integration;
      this.currentCurrencySymbol = userDataResult.tenant?.settings?.currency?.symbol ?? this.currentCurrencySymbol;

      this.userDataLoaderEventEmitter.emit();
      result.success = true;
      return result;
    }
    catch (exc) {
      result.redirectToRegisterPage = false;      
      if (exc?.indexOf("Server returned code: 0") > -1) {
        //redirect to maintenance page
        result.redirectToRegisterPage = false;
      }
    }
    return result;
  }

  finishOnBoarding(tenantSettings: IOnboardingData): Observable<any> {
    this.epLoadingService.start();
    return this.epApiService.post("Tenants/FinishOnBoarding", tenantSettings).pipe(tap(() => {
      this.epLoadingService.stop("Getting started completed");
    }));
  }

  handleAdminLogin(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params == null)
        return;
      let overrideTenant = params[StorageDict.overrideTenant];
      if (overrideTenant == null)
        return;

      this.localStorage.set(StorageDict.overrideTenant, overrideTenant);
    });
  }

  getActionMarginView(actionMarginType: MarginActionType): string {
    switch (actionMarginType) {
      case MarginActionType.Increase:
        return "+";
      case MarginActionType.Decrease:
        return "-";
    }
  }

  getMarginView(pricingMarginType: PricingMarginType): string {
    switch (pricingMarginType) {
      case PricingMarginType.Currency:
        return this.currencySymbol;
      case PricingMarginType.Percentage:
        return percentageSymbol;
    }
  }

  getCompareAtPriceName(compareAtPriceBaseType: CompareAtPriceBaseType): string {
    switch (compareAtPriceBaseType) {
      case CompareAtPriceBaseType.Price:
        return "Price";
      case CompareAtPriceBaseType.Cost:
        return "Cost";
    }
  }

  getRoundingView(roundType: RoundFunctionTypes): string {
    switch (roundType) {
      case RoundFunctionTypes.Down:
        return down;
      case RoundFunctionTypes.Up:
        return up;
    }
  }

  getPriceSuffixView(priceSuffix: PriceSuffixType): string {
    switch (priceSuffix) {
      case PriceSuffixType.NinetyFive:
        return ninetyFive;
      case PriceSuffixType.NinetyNine:
        return ninetyNine;
      case PriceSuffixType.Zero:
        return zero;
    }
  }

  getBoundaryTypeView(boundaryType: MaxLimitMarginBoundaryType): string {
    switch (boundaryType) {
      case MaxLimitMarginBoundaryType.Below:
        return "Below";
      case MaxLimitMarginBoundaryType.Above:
        return "Above";
      case MaxLimitMarginBoundaryType.BelowAndAbove:
        return "Below/Above";
    }
  }

  hideCostColumnIfNecessary(columns): void {
    const shouldHideCost = this?.currentTenant?.settings?.viewOptions?.shouldHideCost;
  
    if (shouldHideCost) {
      // Find the index of the 'cost' column by checking the 'property' or direct string match
      const costColumnIndex = columns.findIndex(x => x.property === "cost" || x === "cost");
  
      // If the 'cost' column exists, remove it using splice
      if (costColumnIndex !== -1) {
        columns.splice(costColumnIndex, 1);
      }
    }
  }  

  get anomalyPercentageLimit(): number {
    return this.currentTenant.settings?.viewOptions?.overPricedMarginPercentage;
  }

  get anomalyPriceLimit(): number {
    return this.currentTenant.settings?.viewOptions?.overPricedMarginPercentage;
  }

  get userCurrency(): ICurrency {
    return this.currentTenant?.settings?.currency
  }

  get isDemoPlatform(): boolean {
    return this.currentTenant?.id === DemoPlatformId;
  }

  get isDynamicPricingEnabled(): boolean {
    return this.currentTenant?.settings?.ruleOptions?.pricingStrategyOptions?.enabled;
  }

  get shouldHideCost() : boolean {
    return this.currentTenant?.settings?.viewOptions?.shouldHideCost;
  }

  enableDynamicPricing(): void {
    if(this.currentTenant?.settings?.ruleOptions?.pricingStrategyOptions?.enabled != null){
      this.currentTenant.settings.ruleOptions.pricingStrategyOptions.enabled = true;
    }
  }

  get showOnlyVerifiedProducts() : boolean {    
    return this.currentTenant?.settings?.viewOptions?.showVerifiedProductsOnly;
  }
}
